import * as React from "react";
import styled from "styled-components";
import Layout from "../../../../../components/Layout/Layout.js";
import useContentful from "../../../../../utils/useContentful";
import useContentful2 from "../../../../../utils/useContentful2";
import { Link } from "gatsby";
import MetaTags from "react-meta-tags";
import Title from "../../../../../components/Title.js";
import Logos from "../../../../../components/proyectos/pais/tasa/Logos.js";
import Navegationbeca from "../../../../../components/proyectos/pais/becatalento/navegationbeca.js";
import Historianorich from "../../../../../components/proyectos/pais/becatalento/Historianorich.js";
import Historianorichmid from "../../../../../components/proyectos/pais/becatalento/Historianorichmid.js";
import MidItemsContainer from "../../../../../components/proyectos/pais/becatalento/MidItems.js";
import Bottomsection from "../../../../../components/proyectos/pais/becatalento/Bottomsection.js";
import Sliderphotos from "../../../../../components/quienessomos/Historia/Sliderphotos.js";
import Testimonials from "../../../../../components/proyectos/pais/becatalento/Testimonials.js";
import "../../../../../styles/global.css";
import { Helmet } from "react-helmet";
import { CustomBreadcrumb } from "../../../../../components/CustomBreadcrumb.js";

const Container = styled.div`
  position: relative;
  width: 100%;
  /*max-width: 1440px;*/
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 78px;
`;

const Pstyled = styled.p`
  color: var(--gris);
  width: 65%;
  padding: 20px 30px;
  text-align: center;
  line-height: 22px;

  p {
    margin-bottom: 16px;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Rectangulo = styled.div`
  display: flex;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  color: var(--gris);
  width: 600px;
  height: 96px;
  background-color: #f5f5f5;
  padding: 0px;

  @media (max-width: 900px) {
    width: 90%;
    height: auto;
    flex-direction: column;
    padding: 16px;
    p {
      font-size: 18px;
    }
  }
`;

const Titlecontainer = styled.div`
  color: ${(p) => p.co};
  font-size: 38px;
  font-weight: 700;
  text-align: center;
`;

const Videocontainer = styled.div`
  padding: 0px 3%;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  iframe {
    width: 90%;
    border-radius: 20px;
    overflow: hidden;
  }
  @media (max-width: 900px) {
    padding: 0px 0%;

    iframe {
      width: 100%;
      margin-bottom: 30px;
    }
  }
`;

const Text = styled.div`
  width: 100%;

  :nth-child(1) {
    color: var(--celesteoscuro);
    margin-bottom: 12px;
    font-weight: 700;
  }
`;

const Text2 = styled.div`
  width: 100%;
`;

const Banner = styled.img`
  position: center;
  width: 100%;
`;

const GeneralEntryId = "8zG9dMMm9VFjkRLfm9y3W";
const ProyectosEntry = "3Mfj3eAWzZD0hnXjw8JEXd";
const BecaTalentoEntry = "2skIqYayPxU9NJtmaEwuys";
const HomeEntryId = "2oDMDgz8LGunEWkUp6HTSA";

const Paragraph21 = ({ content }) => {
  return (
    <>
      {content.map((t, index) => {
        return <Text2>{t.value} </Text2>;
      })}
    </>
  );
};

const links = [
  { name: "Proyectos", to: "/proyectos" },
  { name: "Innovación Social País", to: "/proyectos/pais" },
  { name: "Beca Talento", to: "/proyectos/pais/becatalento" },
];

const IndexPage = ({ location }) => {
  const { data: proyectos, fetched } = useContentful2({ id: ProyectosEntry });
  const { data: general, fetched: fetched2 } = useContentful({
    id: GeneralEntryId,
  });
  const { data: home, fetched: fetched3 } = useContentful({ id: HomeEntryId });
  const { data: becatalento, fetched: fetched4 } = useContentful2({
    id: BecaTalentoEntry,
  });

  // titulo={adopcion.fields.heroTitulo} prop example
  return (
    <>
      {fetched && fetched2 && fetched3 && fetched4 ? (
        <>
          <MetaTags>
            <title>{proyectos.fields.pageTitlePais[2]}</title>
            <meta
              name={proyectos.fields.metaDescriptionPais[2]}
              content={proyectos.fields.metaContentPais[2]}
            />
            <meta name="robots" content="index,follow" />
            <link
              rel="canonical"
              href="https://www.aporta.org.pe/proyectos/pais/becatalento/sobre"
            />
            <link
              rel="alternate"
              hrefLang="x-default"
              href="https://www.aporta.org.pe/proyectos/pais/becatalento/sobre"
            />
            <link
              rel="alternate"
              hrefLang="es-PE"
              href="https://www.aporta.org.pe/proyectos/pais/becatalento/sobre"
            />
          </MetaTags>

          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Aporta",
                    item: "https://www.aporta.org.pe/",
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: "Proyectos",
                    item: "https://www.aporta.org.pe/proyectos/",
                  },
                  {
                    "@type": "ListItem",
                    position: 3,
                    name: `Innovación Social País`,
                    item: `https://www.aporta.org.pe/proyectos/pais/`,
                  },
                  {
                    "@type": "ListItem",
                    position: 4,
                    name: `Beca talento`,
                    item: `https://www.aporta.org.pe/proyectos/pais/becatalento`,
                  },
                  {
                    "@type": "ListItem",
                    position: 5,
                    name: `Sobre Beca Talento`,
                    item: `https://www.aporta.org.pe/proyectos/pais/becatalento/sobre`,
                  },
                ],
              })}
            </script>
          </Helmet>

          <Layout
            margen={false}
            direccion={general.fields.direccion}
            facebook={general.fields.facebook}
            instagram={general.fields.instagram}
            linkedin={general.fields.linkedin}
            email={general.fields.email}
            telefono={general.fields.telefono}
            youtube={general.fields.youtube}
          >
            <Container>
              <br></br>
              <CustomBreadcrumb
                links={links}
                currentPage="Sobre Beca Talento"
              />
              <br></br>
              <Title
                color={"var(--celeste"}
                colortext={"var(--negro)"}
                container={becatalento.fields.abouttitle}
              ></Title>
              <Pstyled>{becatalento.fields.aboutsubtitle}</Pstyled>
              <br></br>
              <Navegationbeca selection={"1"}></Navegationbeca>
              <br></br>
              <br></br>
              <Historianorich
                titulo={becatalento.fields.aboutfirstRowTitle}
                text={becatalento.fields.aboutfirstRowText.content}
                fontc={"var(--naranja)"}
              >
                <Videocontainer>
                  <iframe
                    width="560"
                    height="400"
                    src={becatalento.fields.aboutfirstRowVideo}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </Videocontainer>
              </Historianorich>
              <br></br>
              <Historianorichmid
                titulo={becatalento.fields.aboutsecondRowTitle}
                text={becatalento.fields.aboutsecondRowText.content}
                fontc={"var(--morado)"}
              ></Historianorichmid>
              <MidItemsContainer
                img1={becatalento.fields.aboutsecondRowLImage.fields.file.url}
                titulo1={becatalento.fields.aboutsecondRowLTitle}
                text1={becatalento.fields.aboutsecondRowLText.content}
                img2={becatalento.fields.aboutsecondRowRImage.fields.file.url}
                titulo2={becatalento.fields.aboutsecondRowRTitle}
                text2={becatalento.fields.aboutsecondRowRText.content}
              ></MidItemsContainer>

              {becatalento.fields.aboutGallery ? (
                <>
                  <Titlecontainer co={"var(--verde)"}>
                    Conoce a nuestros becados
                  </Titlecontainer>
                  <Sliderphotos
                    galeria={becatalento.fields.aboutGallery}
                  ></Sliderphotos>
                </>
              ) : (
                <></>
              )}
              {becatalento.fields.abouttestimony ? (
                <>
                  <Titlecontainer co={"var(--naranja)"}>
                    Testimonios
                  </Titlecontainer>
                  <Testimonials card={becatalento.fields.abouttestimony} />
                </>
              ) : (
                <></>
              )}
              {becatalento.fields.aboutLogos ? (
                <>
                  <Titlecontainer co={"var(--celeste)"}>
                    Un proyecto de :
                  </Titlecontainer>
                  <Logos cards={becatalento.fields.aboutLogos}></Logos>
                </>
              ) : (
                <></>
              )}
              <br></br>
              <br></br>
            </Container>
          </Layout>
        </>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            marginTop: "0px",
            padding: "10%",
          }}
        >
          {" "}
          <svg
            version="1.1"
            id="L9"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBlackground="new 0 0 0 0"
            xmlSpace="preserve"
          >
            <path
              fill="var(--celeste)"
              d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="1s"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      )}
    </>
  );
};

export default IndexPage;
